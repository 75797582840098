







































import { Props } from "./types"
import { defineComponent, reactive } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"
import { goToPath } from "@/router"
import ApplicationError from "@/models/Errors/ApplicationError"

const sofieTokenProvider = new SofieTokenProvider()

interface State {
  processing: boolean
}

const propsOptions = {
  scopes: {
    type: String,
    default: "",
  },
  redirectPath: {
    type: String,
    default: "/",
  },
  clientId: {
    type: String,
  },
}

export default defineComponent({
  props: propsOptions,
  components: {
    I18nFormattedMessage,
  },
  setup(props: Props) {
    const state = reactive<State>({
      processing: false,
    })

    // clientIdがない状態の移動はおかしいので、元のページに戻る
    if (!props.clientId || !props.scopes) {
      goToPath(props.redirectPath)
      return
    }

    const reCheckToken = (retry = 3) => {
      if (retry <= 0) return Promise.reject()
      return new Promise<void>(resolve => {
        setTimeout(async () => {
          try {
            const scopes = props.scopes
              .replaceAll(" ", ",")
              .replaceAll("https://graph.microsoft.com/", "")
            await sofieTokenProvider.getGraphApiTokenAsync(scopes)
            resolve()
          } catch {
            return await reCheckToken(retry - 1)
          }
        }, 3000)
      })
    }

    const onLogin = async () => {
      state.processing = true
      try {
        await sofieTokenProvider.consentAndGetTokenAsync(
          props.clientId,
          props.scopes
        )
        await reCheckToken()
        goToPath(props.redirectPath)
      } catch (e) {
        throw new ApplicationError({
          message: `認可に失敗しました。処理が取り消されたか、権限が不足している可能性があります。\n${e.message}`,
          showMessageModal: true,
        })
      } finally {
        state.processing = false
      }
    }
    return {
      state,
      onLogin,
    }
  },
})
